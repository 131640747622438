<div class="page">
  <back-button (click)="closeConnectionAndGoBack()"></back-button>
  <company-info [company]="company"></company-info>
  <div class="content">
    <div class="app-container" *ngIf="url">
      <img src="../../../assets/screen.png" alt="" class="screen" />
      <iframe [src]="url" frameborder="0" id="app-frame"></iframe>
    </div>
    <div class="export-container">
      <h1 class="export">Användardata</h1>
      <h4 class="breadtext-section">AnvändarId:</h4>
      <h4 class="breadtext">{{ company.ownerId }}</h4>
      <h4 class="breadtext-section">Inloggningsmail:</h4>
      <h4 class="breadtext">{{ company.adminPortalDisplayData.loginEmail }}</h4>
      <h4 class="breadtext-section">Prenumerationsstatus:</h4>
      <h4 class="breadtext subscriptionInfoTextHandler">{{ company.adminPortalDisplayData.subInfoString }}</h4>

      <h1 class="export">Export</h1>

      <mat-form-field appearance="outline">
        <mat-label>Räkenskapsår</mat-label>
        <mat-select [formControl]="financialYearControl">
          <mat-option
            *ngFor="let financialYear of financialYears"
            [value]="financialYear"
          >
            {{ financialYear.start | date: "yyyy-MM-dd" }} -
            {{ financialYear.end | date: "yyyy-MM-dd" }}
          </mat-option>
        </mat-select></mat-form-field
      >
      <div class="exports">
        <sie-export
        [token]="companyToken"
          [disabled]="!selectedFinancialYear"
          [selectedFinancialYear]="selectedFinancialYear"
          [company]="company"
        ></sie-export>
      </div>
    </div>
  </div>
</div>
